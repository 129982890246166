.section-banner {
    @media print {
        background: transparent;
        padding: 0;
    }

    .container {
        @media print {
            min-height: auto;
        }
    }

    .banner-text {
        @media print {
            max-width: 40%;
            width: 40%;
        }
    }

    h1 {
        @media print {
            color: #000;
        }
    }
}

.card-resources {

    .content {
        @media print {
            padding: 0 !important;
        }
    }

    .card-title {
        @media print {
            color: #000;
            text-align: left;
            margin-bottom: 0;
        }
    }
}

.footer-nav {
    @media print {
        display: none;
    }
}

.footer-contacts {
    .inner { 
        .col {
                &:first-child {
                @media print {
                    display: none;
                }
            }

            > * {
                @media print {
                    padding: 0;
                }
            }
        }
    }
}

.btn {
    @media print {
        display: none;
    }
}

.section-post-info,
.section-sponsors,
.footer-bottom {
    @media print {
        padding: 0;
    }
}

.breadcrumbs-nav {
    @media print {
        padding: 15px 0;
    }
}

hr,
figure,
.search-form {
    @media print {
        display: none;
    }
}

.footer-bottom-menu {
    @media print {
        display: none;
    }
}

.copyright {
    @media print {
        padding: 15px 0;
        display: block;
        text-align: left;
        max-width: none;
    }
}

table {
    td,
    th {
        @media print {
            padding: 9px 17px;
        }
    }
}

h4,
.h4,
p {
    @media print {
        margin-bottom: 20px;
    }
}

.partners-list {
    @media print {
        display: table;
        margin-bottom: 25px;
        
    }

    li {
        @media print {
            width: 33.3333333%;
            padding: 5px;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
        }
    }
}

.section-banner-home { 
    .banner-img {
        @media print {
            height: auto;
        }
    }

    .col {
        &:nth-child(2) {
            @media print {
                padding: 0 15px;
            }
        }
    }
}

.card-text {
    .inner {
        @media print {
            padding: 15px;
        }
    }
}

.section-upcoming-events {
    .card-block {
        @media print {
            max-width: 100%;
        }

        .col {
            @media print {
                width: 50% !important;
                display: table-cell !important;
            }
        }
    }

    .slick-track {
        @media print {
            display: table !important;
            width: 100% !important;
        }
    }

    .card {
        @media print {
            max-width: 100%;
        }

        header {
            @media print {
                display: none;
            }
        }

        .content {
            @media print {
                padding: 10px;
            }

            .card-title {
                @media print {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.card-resources {
    @media print {
        min-height: auto;
    }
}

* {
    @media print {
        background: transparent !important;
        color: #000 !important;
    }

    &:after,
    &:before {
        @media print {
            background: transparent !important;
        }
    }
}

.section-content {
    @media print {
        h3 {
            margin-bottom: 15px;
            font-size: 20px;
        }

        p {
            margin-bottom: 15px;
        }
    }
}

.heading {
    a {
        @media print {
            display: none;
        }
    }
}

.banner-thumbanail {
    a {
        @media print {
            display: none;
        }
    }
}

.card-resources-holder,
.breadcrumbs-nav,
nav {
    @media print {
        display: none;
    }
}

.section-banner-home .col:first-child,
.section-banner-home .col:nth-child(2)  {
    @media print {
        max-width: 100%;
        flex: auto;
    }
}